import { onMounted, computed } from 'vue';
import { useStore } from '@/store';
import { WebsiteDetailsActions } from '@/store/modules/details';

export default function useWebsiteDetails() {
	const store = useStore();
	const websiteDetails = computed(() => store.getters.websiteDetails);

	onMounted(() => {
		store.dispatch(WebsiteDetailsActions.getDetails);
	});

	return { websiteDetails };
}
