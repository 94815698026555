import axios from 'axios';
import { Contact } from '@/interfaces';

const contactApiUrl = '/api/contact/';

export const ContactService = {
	async send(data: Contact) {
		return axios.post(contactApiUrl, data);
	},
};
