<template>
	<section class="contact-map-section">
		<div class="map_box">
			<iframe
				title="map"
				id="gmap_canvas"
				:src="`https://maps.google.com/maps?q=${section.content}&t=&z=13&ie=UTF8&iwloc=&output=embed`"
			></iframe>
		</div>
	</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Section } from '@/interfaces';

export default defineComponent({
	props: {
		section: {
			type: Object as () => Section,
			required: true,
		},
	},
});
</script>

<style lang="scss" src="./VMap.scss"></style>
