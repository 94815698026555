
import { defineComponent } from 'vue';
import Markdown from '@/plugins/markdown.vue';

export default defineComponent({
	components: {
		Markdown,
	},
	props: {
		markdown: {
			type: String,
			default: undefined,
		},
	},
});
