<template>
	<section class="faq-v1 pt-110 pb-60">
		<div class="container">
			<div class="row">
				<div class="col-lg-6">
					<Markdown :text="section.content" />
				</div>
				<div class="col-lg-6 wow slideInRight">
					<div class="faq-img mb-60">
						<img :src="section.image" width="513" height="622" alt="faq image">
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Markdown from '@/plugins/markdown.vue';
import { Section } from '@/interfaces';

export default defineComponent({
	components: {
		Markdown,
	},
	props: {
		section: {
			type: Object as () => Section,
			required: true,
		},
	},
});
</script>

<style lang="scss" src="./VFaq.scss"></style>
