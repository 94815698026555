<template>
	<div v-if="page">
		<VMarkdownContent v-bind="page" />

		<template v-for="section in page.sections" :key="section.name">
			<component :is="section.component" :id="section.name" :section="section" />
		</template>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import usePage from '@/composable/usePage';
import VMarkdownContent from '@/components/sections/VMarkdownContent/VMarkdownContent.vue';

export default defineComponent({
	components: {
		VMarkdownContent,
	},
	setup() {
		const { page } = usePage('404');
		return { page };
	},
});
</script>
