<template>
	<div v-html="markdown" />
</template>

<script>
import { toRefs } from 'vue';
import marked from 'marked';

marked.Renderer.prototype.paragraph = (text) => {
	if (text.startsWith('<span')) {
		return `${text}\n`;
	}
	return `<p>${text}</p>`;
};

export default {
	props: {
		text: {
			type: String,
			default: '',
		},
	},
	setup(props) {
		const { text } = toRefs(props);
		const markdown = marked(text.value);
		return { markdown };
	},
};
</script>
