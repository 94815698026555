<template>
	<div v-if="markdown" class="container pt-60 pb-60">
		<markdown :text="markdown" :class="$style.markdown" />
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Markdown from '@/plugins/markdown.vue';

export default defineComponent({
	components: {
		Markdown,
	},
	props: {
		markdown: {
			type: String,
			default: undefined,
		},
	},
});
</script>

<style lang="scss" module>
.markdown {
	padding: 1px;

	:global {
		h2 {
			margin-bottom: 25px;
		}
		p:not(p:last-child) {
			margin-bottom: 20px;
		}
	}
}
</style>
