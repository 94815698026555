import { GetterTree } from 'vuex';
import { RootState } from '@/store/state';
import { WebsiteDetails } from '@/interfaces';
import { State } from './state';

export type Getters<S = State> = {
	websiteDetails(state: S): WebsiteDetails | undefined;
};

export const getters: GetterTree<State, RootState> & Getters = {
	websiteDetails: state => state.websiteDetails,
};
