<template>
	<div class="form_group">
		<Dropdown
			v-model="value"
			:options="options"
			:placeholder="placeholder"
			:class="[$style.select, {[$style['is-invalid']]: error }]"
		/>
		<div v-if="error" :class="$style.error" class="invalid-feedback">
			{{ error.replace('Value', 'Input') }}
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useVModels } from '@vueuse/core';
import { Dropdown } from '@/plugins/PrimeVue';

export default defineComponent({
	components: {
		Dropdown,
	},
	props: {
		modelValue: {
			type: String,
			required: true,
		},
		placeholder: {
			type: String,
			required: true,
		},
		options: {
			type: Array as () => string[],
			required: true,
		},
		error: {
			type: String,
			default: '',
		},
	},
	setup(props) {
		const { modelValue } = useVModels(props);
		return { value: modelValue };
	},
});
</script>

<style lang="scss" module>
.is-invalid {
	border: 1px solid $error-color !important;
}

.select {
	display: flex;
    align-items: center;
	width: 100%;
	height: 55px;
	padding: 0 10px;
	border: none;
	margin-bottom: 30px;
}

.error {
	display: block;
	position: absolute;
	bottom: -25px;
	padding: 0 20px;
}
</style>
