<template>
	<section
		:style="{ 'background-image': `url(${section.bgImage})` }"
		class="service-v1 pt-120 pb-140 light-bg"
	>
		<div class="container">
			<Markdown :text="section.content" />
		</div>
	</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Markdown from '@/plugins/markdown.vue';
import { Section } from '@/interfaces';

export default defineComponent({
	components: {
		Markdown,
	},
	props: {
		section: {
			type: Object as () => Section,
			required: true,
		},
	},
});
</script>

<style lang="scss" src="./VService.scss"></style>
