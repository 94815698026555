import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store/state';
import { api } from '@/api';
import { Mutations, MutationTypes } from './mutations';
import { State } from './state';

export enum ActionTypes {
	getSingle = 'GET_SINGLE',
}

type AugmentedActionContext = {
	commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
	[ActionTypes.getSingle]({ commit }: AugmentedActionContext, payload: string): void;
}
export const actions: ActionTree<State, RootState> & Actions = {
	async [ActionTypes.getSingle]({ commit }, payload) {
		try {
			const response = await api.page.get(payload);
			if (response) {
				commit(MutationTypes.SET_SINGLE, response.data);
			}
		} catch (error) {
			commit(MutationTypes.SET_ERROR, error);
		}
	},
};
