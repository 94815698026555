import { onMounted, computed } from 'vue';
import { useStore } from '@/store';
import { PageActions } from '@/store/modules/page';

export default function usePage(name: string) {
	const store = useStore();
	const page = computed(() => store.getters.page(name));

	onMounted(() => {
		store.dispatch(PageActions.getSingle, name);
	});

	return { page };
}
