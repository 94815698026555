
import { defineComponent } from 'vue';
import useHeader from '@/composable/useHeader';

export default defineComponent({
	props: {
		logo: {
			type: String,
			required: true,
		},
		contactMail: {
			type: String,
			required: true,
		},
		phoneNumber: {
			type: String,
			required: true,
		},
		location: {
			type: String,
			required: true,
		},
		profiles: {
			type: Object,
			required: true,
		},
	},
	setup() {
		return useHeader();
	},
});
