
import { defineComponent } from 'vue';
import { Section } from '@/interfaces';

export default defineComponent({
	props: {
		section: {
			type: Object as () => Section,
			required: true,
		},
	},
});
