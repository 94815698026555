import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import Dropdown from 'primevue/dropdown';

import { App } from 'vue';

export const PrimeVueSetup = (app: App<Element>) => {
	app.use(PrimeVue);
	app.use(ToastService);
};

export {
	Dropdown,
	Toast,
};
