import { MutationTree } from 'vuex';
import { Page } from '@/interfaces';
import { State } from './state';

export enum MutationTypes {
	SET_SINGLE = 'SET_SINGLE',
	SET_ERROR = 'SET_ERROR',
}

export type Mutations<S = State> = {
	[MutationTypes.SET_SINGLE](state: S, payload: Page): void;
	[MutationTypes.SET_ERROR](state: S, payload: Record<string, string>): void;
};

export const mutations: MutationTree<State> & Mutations = {
	[MutationTypes.SET_SINGLE](state, payload) {
		const pages = state.pages.filter((page: Page) => page.name !== payload.name);
		pages.push(payload);
		state.pages = pages;
	},
	[MutationTypes.SET_ERROR](state, payload) {
		state.error = payload;
	},
};
