
import { defineComponent } from 'vue';
import { useVModels } from '@vueuse/core';

export default defineComponent({
	props: {
		modelValue: {
			type: String,
			required: true,
		},
		name: {
			type: String,
			required: true,
		},
		type: {
			type: String,
			required: true,
		},
		placeholder: {
			type: String,
			required: true,
		},
		icon: {
			type: String,
			default: undefined,
		},
		required: {
			type: Boolean,
			default: false,
		},
		error: {
			type: String,
			default: '',
		},
	},
	setup(props) {
		const { modelValue } = useVModels(props);
		return { value: modelValue };
	},
});
