import { InjectionKey } from 'vue';
import { createStore, createLogger, Store as VuexStore, useStore as baseUseStore } from 'vuex';
import page, { Store as PageStore } from './modules/page';
import websiteDetails, { Store as WebsiteDetailsStore } from './modules/details';
import { RootState } from './state';

export type RootStore = PageStore<Pick<RootState, 'page'>>
	& WebsiteDetailsStore<Pick<RootState, 'websiteDetails'>>;

// define injection key
// eslint-disable-next-line
export const key: InjectionKey<VuexStore<RootState>> = Symbol();
const store = createStore<RootState>({
	plugins: [createLogger()],
	modules: {
		page,
		websiteDetails,
	},
});

export function useStore(): RootStore {
	return baseUseStore(key);
}

export default store;
