const easeInOutQuad = (currentTime: number, start: number, change: number, duration: number): number => {
	let newCurrentTime = currentTime;
	newCurrentTime /= duration * 0.5;

	if (newCurrentTime < 1) {
		return (change / 2) * newCurrentTime * newCurrentTime + start;
	}

	newCurrentTime -= 1;
	return (-change / 2) * (newCurrentTime * (newCurrentTime - 2) - 1) + start;
};

const smoothScroll = (
	to: number,
	duration: number,
	element: HTMLElement = document.documentElement,
	property: 'scrollTop' | 'scrollLeft' = 'scrollTop',
): void => {
	const el = element;
	const start = el[property];
	const change = to - start;
	const startDate = new Date().getTime();

	const animateScroll = () => {
		const currentDate = new Date().getTime();
		const currentTime = currentDate - startDate;

		el[property] = easeInOutQuad(currentTime, start, change, duration);

		if (currentTime < duration) {
			requestAnimationFrame(animateScroll);
		} else {
			el[property] = to;
		}
	};
	animateScroll();
};

export { smoothScroll };
