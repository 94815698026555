<template>
	<section class="hero-area-v1">
		<Markdown
			:text="section.content"
			class="hero-slider-one"
		/>
	</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Markdown from '@/plugins/markdown.vue';
import { Section } from '@/interfaces';

export default defineComponent({
	components: {
		Markdown,
	},
	props: {
		section: {
			type: Object as () => Section,
			required: true,
		},
	},
	mounted() {
		// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
		// @ts-ignore
		// eslint-disable-next-line no-undef
		$('.hero-slider-one').slick({
			dots: false,
			arrows: true,
			infinite: true,
			autoplay: true,
			Speed: 1000,
			fade: true,
			prevArrow: '<div class="prev"><i class="fas fa-angle-left"></i></div>',
			nextArrow: '<div class="next"><i class="fas fa-angle-right"></i></div>',
			slidesToShow: 1,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 1180,
					settings: {
						arrows: false,
					},
				},
				{
					breakpoint: 480,
					settings: {
						arrows: false,
					},
				},
			],
		});
	},
});
</script>

<style lang="scss" src="./VHero.scss"></style>
