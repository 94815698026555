import { Page } from '@/interfaces';
import axios from 'axios';

const apiUrl = '/api/pages/';

export const PageService = {
	async get(name: string) {
		return axios.get<Page>(`${apiUrl}${name}/`);
	},
};
