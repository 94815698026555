<template>
	<div id="home">
		<!--====== Start Preloader ======-->
		<transition name="v-fade">
			<div v-if="showPreloader" class="preloader">
				<div class="lds-ellipsis">
					<span></span>
					<span></span>
					<span></span>
				</div>
			</div>
		</transition>
		<!--====== End Preloader ======-->

		<Toast />
		<TheHeader v-if="websiteDetails"  v-bind="websiteDetails" />
		<div :class="$style.content">
			<router-view />
		</div>
		<TheFooter v-if="websiteDetails"  v-bind="websiteDetails" />
		<BackToTop />
	</div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { Toast } from '@/plugins/PrimeVue';
import TheHeader from '@/components/layout/TheHeader/TheHeader.vue';
import TheFooter from '@/components/layout/TheFooter/TheFooter.vue';
import BackToTop from '@/components/general/BackToTop/BackToTop.vue';
import UseWebsiteDetails from '@/composable/useWebsiteDetails';

export default defineComponent({
	components: {
		TheHeader,
		TheFooter,
		Toast,
		BackToTop,
	},
	setup() {
		const { websiteDetails } = UseWebsiteDetails();
		const showPreloader = ref(true);
		setTimeout(() => showPreloader.value = false, 500);
		return { showPreloader, websiteDetails };
	},
});
</script>

<style lang="scss" src="@/assets/scss/app.scss"></style>
<style lang="scss" module>
.content {
	min-height: calc(100vh - 375px);
}
</style>
