import { GetterTree } from 'vuex';
import { RootState } from '@/store/state';
import { Page } from '@/interfaces';
import { State } from './state';

export type Getters<S = State> = {
	page(state: S): (name: string) => Page | undefined;
};

export const getters: GetterTree<State, RootState> & Getters = {
	page: state => (name: string): Page | undefined => {
		const single = state.pages.find(page => page.name === name);
		return single;
	},
};
