<template>
	<section :class="$style.section">
		<iframe src="/static/ISO/KRMBV-ISO9001-2015.pdf" width="100%" height="1200px" />
	</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>

<style lang="scss" module>
.section {
	height: 1195px;
}
</style>
