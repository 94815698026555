// @ts-nocheck

import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useOnResize, useOnScroll, useOnOutsidePress } from 'vue-composable';

export default function useHome() {
	const { scrollTop } = useOnScroll();
	const router = useRouter();
	const navbar = ref();
	const { width } = useOnResize(document.body);
	const isNavbarOpen = ref(false);

	const toggleNavbar = () => {
		isNavbarOpen.value = !isNavbarOpen.value;
	};

	const closeNavbar = () => {
		isNavbarOpen.value = false;
	};

	const goTo = (e: Event) => {
		e.preventDefault();
		if (isNavbarOpen.value) {
			closeNavbar();
		}
		router.push(e.target.hash);
	};

	useOnOutsidePress(navbar, closeNavbar);

	onMounted(() => {
		// Variables
		const navMenu = $('.nav-menu');

		// adds toggle button to li items that have children
		/* @ts-ignore */
		navMenu.find('li a').each(function () {
			if ($(this).next().length > 0) {
				$(this)
					.parent('li')
					.append(
						'<span class="dd-trigger"><i class="fas fa-angle-down"></i></span>',
					);
			}
		});
		// expands the dropdown menu on each click
		navMenu.find('li .dd-trigger').on('click', function (e) {
			e.preventDefault();
			$(this)
				.parent('li')
				.children('ul')
				.stop(true, true)
				.slideToggle(350);
			$(this).parent('li').toggleClass('active');
		});
	});

	return { scrollTop, toggleNavbar, closeNavbar, isNavbarOpen, width, navbar, goTo };
}
