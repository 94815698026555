import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store/state';
import { api } from '@/api';
import { Mutations, MutationTypes } from './mutations';
import { State } from './state';

export enum ActionTypes {
	getDetails = 'GET_DETAILS',
}

type AugmentedActionContext = {
	commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
	[ActionTypes.getDetails]({ commit }: AugmentedActionContext): void;
}
export const actions: ActionTree<State, RootState> & Actions = {
	async [ActionTypes.getDetails]({ commit }) {
		try {
			const response = await api.details.get();
			if (response) {
				commit(MutationTypes.SET_DETAILS, response.data);
			}
		} catch (error) {
			commit(MutationTypes.SET_ERROR, error);
		}
	},
};
