import { render } from "./App.vue?vue&type=template&id=a85c545a"
import script from "./App.vue?vue&type=script&lang=ts"
export * from "./App.vue?vue&type=script&lang=ts"

import "@/assets/scss/app.scss?vue&type=style&index=0&lang=scss"
const cssModules = script.__cssModules = {}
import style1 from "./App.vue?vue&type=style&index=1&id=a85c545a&lang=scss&module=true"
cssModules["$style"] = style1
script.render = render

export default script