
import { defineComponent } from 'vue';
import usePage from '@/composable/usePage';
import VMarkdownContent from '@/components/sections/VMarkdownContent/VMarkdownContent.vue';

export default defineComponent({
	components: {
		VMarkdownContent,
	},
	setup() {
		const { page } = usePage('home');
		return { page };
	},
});
