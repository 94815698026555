<template>
	<transition name="v-fade">
		<a v-if="isVisible" :class="$style.backToTop" href="#" @click="backToTop">
			<i class="fas fa-angle-up"></i>
		</a>
	</transition>
</template>

<script>
import { computed } from 'vue';
import { useOnScroll } from 'vue-composable';
import { smoothScroll } from '@/utils/smoothScroll';

export default {
	setup() {
		const { scrollTop } = useOnScroll();
		const isVisible = computed(() => scrollTop.value > 600);

		const backToTop = (e) => {
			e.preventDefault();
			smoothScroll(0, 1500);
		};

		return { isVisible, backToTop };
	},
};
</script>

<style lang="scss" module>
.backToTop {
  background: $theme-color;
  border-radius: 50%;
  bottom: 30px;
  color: $white;
  cursor: pointer;
  display: block;
  font-size: 20px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  position: fixed;
  right: 30px;
  text-align: center;
  text-decoration: none;
  transition: .3s;
  z-index: 337;
}
.backToTop:hover,
.backToTop:focus {
  background: $heading-color;
  color: $white;
}
</style>
