
import { defineComponent } from 'vue';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import CookieLaw from '@/components/CookieLaw/CookieLaw.vue';

export default defineComponent({
	components: {
		CookieLaw,
	},
	props: {
		logo: {
			type: String,
			required: true,
		},
		contactMail: {
			type: String,
			required: true,
		},
		phoneNumber: {
			type: String,
			required: true,
		},
		location: {
			type: String,
			required: true,
		},
		profiles: {
			type: Object,
			required: true,
		},
		copyRight: {
			type: String,
			required: true,
		},
	},
});
