import { WebsiteDetails } from '@/interfaces';
import axios from 'axios';

const apiUrl = '/api/details/';

export const DetailService = {
	async get() {
		return axios.get<WebsiteDetails>(apiUrl);
	},
};
