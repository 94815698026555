import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { useOnScroll } from 'vue-composable';
import Home from '@/views/Home/Home.vue';
import Privacy from '@/views/Privacy/Privacy.vue';
import ISO from '@/views/ISO/ISO.vue';
import PageNotFound from '@/views/PageNotFound/PageNotFound.vue';
import { smoothScroll } from '@/utils/smoothScroll';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'home',
		component: Home,
	},
	{
		path: '/privacy',
		name: 'privacy',
		component: Privacy,
	},
	{
		path: '/KRMBV-ISO9001-2015.pdf',
		name: 'ISO',
		component: ISO,
	},
	{
		path: '/:pathMatch(.*)*',
		name: '404',
		component: PageNotFound,
	},
];

const { scrollTop } = useOnScroll();

interface Route {
	name: string;
	hash: string;
}

const scrollBehavior = (to: Route, from: Route) => {
	if (to.hash) {
		return new Promise((resolve) => {
			const delay = to.name === from.name ? 1 : 500;
			setTimeout(() => {
				const topOffset = scrollTop.value === 0 ? 182 : 90;
				const el: HTMLElement | null = document.getElementById(to.hash.substring(1));
				if (el) {
					smoothScroll((el.offsetTop - topOffset), 1500);
				} else {
					smoothScroll(0, 1500);
				}

				resolve({});
			}, delay);
		});
	}

	if (to.name === from.name) {
		smoothScroll(0, 1500);
		return {};
	}

	return { left: 0, top: 0 };
};

const router = createRouter({
	history: createWebHistory(),
	// eslint-disable-next-line
	// @ts-ignore
	scrollBehavior,
	routes,
});

export default router;
