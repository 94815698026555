import { MutationTree } from 'vuex';
import { WebsiteDetails } from '@/interfaces';
import { State } from './state';

export enum MutationTypes {
	SET_DETAILS = 'SET_DETAILS',
	SET_ERROR = 'SET_ERROR',
}

export type Mutations<S = State> = {
	[MutationTypes.SET_DETAILS](state: S, payload: WebsiteDetails): void;
	[MutationTypes.SET_ERROR](state: S, payload: Record<string, string>): void;
};

export const mutations: MutationTree<State> & Mutations = {
	[MutationTypes.SET_DETAILS](state, payload) {
		state.websiteDetails = payload;
	},
	[MutationTypes.SET_ERROR](state, payload) {
		state.error = payload;
	},
};
