import { reactive } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import { api } from '@/api';
import { useToast } from 'primevue/usetoast';

export default function useHome() {
	const toast = useToast();
	let sending = false;

	const state = reactive({
		name: '',
		email: '',
		phone: '',
		subject: '',
		message: '',
	});
	const rules = {
		name: { required },
		email: { required, email },
		phone: {},
		subject: { required },
		message: { required },
	};

	const v$ = useVuelidate(rules, state);

	const resetState = () => {
		state.name = '';
		state.email = '';
		state.phone = '';
		state.subject = '';
		state.message = '';
	};

	const showSuccessToast = () => {
		toast.add({
			severity: 'success',
			summary: 'Thanks for getting in touch',
			detail: 'Your message has been successfully sent. We will review your message as soon as possible.',
			life: 8000,
		});
	};

	const showErrorToast = () => {
		toast.add({
			severity: 'error',
			summary: 'Fout bij verzenden',
			detail: 'Er is een fout opgetreden bij het verzenden, probeer het later opnieuw.',
			life: 8000,
		});
	};

	const submit = async () => {
		v$.value.$touch();
		if (v$.value.$invalid || sending) {
			return;
		}
		sending = true;

		try {
			await api.contact.send(state);
			resetState();
			showSuccessToast();
			v$.value.$reset();
		} catch (e) {
			showErrorToast();
		}
		sending = false;
	};

	return { state, v$, submit, sending };
}
