<template>
	<div class="form_group">
		<textarea
			v-if="type === 'textarea'"
			v-model="value"
			:required="required"
			:placeholder="placeholder"
			:class="{ [$style['is-invalid']]: error }"
			class="form_control"
		/>
		<input
			v-else
			v-model="value"
			:name="name"
			:placeholder="placeholder"
			:required="required"
			:type="type"
			:class="{ [$style['is-invalid']]: error }"
			class="form_control"
		>
		<i v-if="icon" :class="icon"></i>
		<div v-if="error" :class="$style.error" class="invalid-feedback">
			{{ error.replace('Value', 'Input') }}
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useVModels } from '@vueuse/core';

export default defineComponent({
	props: {
		modelValue: {
			type: String,
			required: true,
		},
		name: {
			type: String,
			required: true,
		},
		type: {
			type: String,
			required: true,
		},
		placeholder: {
			type: String,
			required: true,
		},
		icon: {
			type: String,
			default: undefined,
		},
		required: {
			type: Boolean,
			default: false,
		},
		error: {
			type: String,
			default: '',
		},
	},
	setup(props) {
		const { modelValue } = useVModels(props);
		return { value: modelValue };
	},
});
</script>

<style lang="scss" module>
.is-invalid {
	border: 1px solid $error-color;
}

.error {
	display: block;
	position: absolute;
	bottom: -25px;
	padding: 0 20px;
}
</style>
