
import { defineComponent, ref } from 'vue';
import { Toast } from '@/plugins/PrimeVue';
import TheHeader from '@/components/layout/TheHeader/TheHeader.vue';
import TheFooter from '@/components/layout/TheFooter/TheFooter.vue';
import BackToTop from '@/components/general/BackToTop/BackToTop.vue';
import UseWebsiteDetails from '@/composable/useWebsiteDetails';

export default defineComponent({
	components: {
		TheHeader,
		TheFooter,
		Toast,
		BackToTop,
	},
	setup() {
		const { websiteDetails } = UseWebsiteDetails();
		const showPreloader = ref(true);
		setTimeout(() => showPreloader.value = false, 500);
		return { showPreloader, websiteDetails };
	},
});
